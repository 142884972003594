// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-construccion-de-viviendas-casas-con-contenedores-maritimos-js": () => import("./../../../src/pages/construccion-de-viviendas/casas-con-contenedores-maritimos.js" /* webpackChunkName: "component---src-pages-construccion-de-viviendas-casas-con-contenedores-maritimos-js" */),
  "component---src-pages-construccion-de-viviendas-index-js": () => import("./../../../src/pages/construccion-de-viviendas/index.js" /* webpackChunkName: "component---src-pages-construccion-de-viviendas-index-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-error-page-js": () => import("./../../../src/pages/error-page.js" /* webpackChunkName: "component---src-pages-error-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instalaciones-cargadores-coches-electricos-js": () => import("./../../../src/pages/instalaciones/cargadores-coches-electricos.js" /* webpackChunkName: "component---src-pages-instalaciones-cargadores-coches-electricos-js" */),
  "component---src-pages-nota-legal-js": () => import("./../../../src/pages/nota-legal.js" /* webpackChunkName: "component---src-pages-nota-legal-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-presupuesto-js": () => import("./../../../src/pages/presupuesto.js" /* webpackChunkName: "component---src-pages-presupuesto-js" */),
  "component---src-pages-reformas-electricistas-barcelona-js": () => import("./../../../src/pages/reformas/electricistas-barcelona.js" /* webpackChunkName: "component---src-pages-reformas-electricistas-barcelona-js" */),
  "component---src-pages-reformas-fontaneros-barcelona-js": () => import("./../../../src/pages/reformas/fontaneros-barcelona.js" /* webpackChunkName: "component---src-pages-reformas-fontaneros-barcelona-js" */),
  "component---src-pages-reformas-index-js": () => import("./../../../src/pages/reformas/index.js" /* webpackChunkName: "component---src-pages-reformas-index-js" */),
  "component---src-pages-reformas-reformas-banos-en-barcelona-js": () => import("./../../../src/pages/reformas/reformas-banos-en-barcelona.js" /* webpackChunkName: "component---src-pages-reformas-reformas-banos-en-barcelona-js" */),
  "component---src-pages-reformas-reformas-cocinas-en-barcelona-js": () => import("./../../../src/pages/reformas/reformas-cocinas-en-barcelona.js" /* webpackChunkName: "component---src-pages-reformas-reformas-cocinas-en-barcelona-js" */),
  "component---src-pages-reformas-reformas-locales-barcelona-js": () => import("./../../../src/pages/reformas/reformas-locales-barcelona.js" /* webpackChunkName: "component---src-pages-reformas-reformas-locales-barcelona-js" */),
  "component---src-pages-reformas-reformas-pisos-en-barcelona-js": () => import("./../../../src/pages/reformas/reformas-pisos-en-barcelona.js" /* webpackChunkName: "component---src-pages-reformas-reformas-pisos-en-barcelona-js" */),
  "component---src-pages-xxtest-js": () => import("./../../../src/pages/xxtest.js" /* webpackChunkName: "component---src-pages-xxtest-js" */),
  "component---src-templates-charger-js": () => import("./../../../src/templates/Charger.js" /* webpackChunkName: "component---src-templates-charger-js" */),
  "component---src-templates-house-js": () => import("./../../../src/templates/House.js" /* webpackChunkName: "component---src-templates-house-js" */)
}

