module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-168270100-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Inmovalia - Construcción de viviendas y Reformas en Barcelona","description":"Construcción de viviendas y reformas en Barcelona y alrededores. Construcción de casas con contenedores marítimos. Reformas integrales de locales comerciales, pisos, cocinas y baños. Equipo con gran experiencia de albañiles, electricistas y fontaneros. La mejor empresa de reformas en Barcelona. Pídenos presupuesto gratuito y sin compromiso.","short_name":"Inmovalia Group","start_url":"/","display":"standalone","icon":"./src/assets/img/favicon.png","lang":"es","background_color":"#ffffff","theme_color":"#023513","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"3e31f30ecb8d58253b9a0d6406a6e0c8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.inmovaliagroup.com"},
    }]
